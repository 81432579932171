import { Link } from "gatsby";
import React from "react";
import H1 from "../../components/h1";
import SEO from "../../components/seo";
import { listItemStyle } from "../../helpers/staticData";

const PatientInformation = () => {
	return (
		<div>
			<H1 text="Patient Information" />
			<SEO
				title="Patient Information"
				keywords={[
					`New patients`,
					`Patient information`,
					`Gastroenterologist`,
					`Hepatologist`,
					`Dr Frank`,
					`Dr Brennan`,
					`Dr Spiro`,
					`Dr Raftopoulos`,
					`Dr Ian Yusoff`,
					`Dr Dev Segarajasingam`,
					`Dr Sam Rao`,
					`Dr Duy Pham`,
					`Dr Claire Gordon`,
				]}
				description="Patient information - things you need to know before you come in"
			/>
			<ul>
				<Link className={listItemStyle} to="/patient-information/first-visit">
					<li>First Visit</li>
				</Link>
				{/* <Link to="/patient-information/patient-forms">
        <li>Patient Forms</li>
      </Link> */}
				<Link className={listItemStyle} to="/patient-information/fees-and-charges">
					<li>Fees and Charges</li>
				</Link>
			</ul>
		</div>
	);
};

export default PatientInformation;
